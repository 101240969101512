import { parse as urlParse } from 'url'
import macheSockets from './mache/mache'
import basicTestSpace from './testSpace/testSpace'
import socketTests from '../test/sockets/basic.js';
import $ from 'jquery'
const logic = {}

/*
  This file just inits the sockets for each namespace. i.e the mache namespace
*/







const { pathname } = urlParse(window.location.href)

/*
console.log('%c INITIALIZING SOCKETS. This means it is included in main ', 'color:red')

if (pathname.split('/')[1] === 't' && pathname.split('/')[2] === 'testMacheSpace') {
  const macheKey = $('#key').attr('data-key');
  macheSockets.init(macheKey)
  socketTests(macheKey)
} else if (pathname.split('/')[1] === 'e') {
  console.log('initing mache events')
  macheSockets.init(macheKey);
} else if (pathname.split('/')[1] === 't') {
  const roomKey = $('#key').attr('data-key');
  console.log('%c INIT test sockets. Room key : ', 'color:blue', roomKey)
  basicTestSpace.init(roomKey);
}
*/




export { logic as default }
