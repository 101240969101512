





const macheSockets = {}


const exampleEvents = (socket) => {
  window.macheSocket = socket;
  // socket.on('example_pong', (data) => {
  //   console.log('%c caught example_pong event with data : ', 'color:blue', data)
  //
  //   const response = 'hi friends'
  //   console.log('%c example_pong handler emitting example_response', 'color:blue', response)
  //
  //   socket.emit('example_response', { data : response } )
  // })



}

const randomNumber = Math.random()

macheSockets.init = (macheKey) => {
  console.log('%c Mache test space. Initializing mache sockets. Mache key : ', 'color:blue', macheKey )
  const socket = io('/macheSpace'); //exposed on window object from layout.pug
  // const socket = io('ws://localhost:3000/macheSpace', { transports : ['websocket']} ); //exposed on window object from layout.pug
  exampleEvents(socket)


}




export { macheSockets as default }
