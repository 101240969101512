import axios from 'axios'

const getRandInt = () => Math.floor( Math.random()*100000 );

//create folio, get folio, add mache to folio, add dupe mache to folio (fail), delete folio, try to get deleted folio


const createMache = async(visibility='public') => {
  const { data } = await axios.post('/u/createMache', {
    title : `The best mache ${getRandInt()}`,
    visibility : visibility
  })
  const { createdMache } = data;
  console.log('created mache', createdMache)
  if ( createdMache.title !== 'The mache' && createdMache.settings.visibility !== visibility ) {
    throw new Error('user create mache failed')
  }

  return createdMache
}


const createFolio = async() => {
  // state, visibility, name, description
  const name = `dopeolio_${getRandInt()}`
  const { data } = await axios.post('/u/createFolio', { state : 'opened', visibility : 'everyone', name : name })

  //bs safety checks
  if ( data.createdFolio.name !== name || data.createdFolio.state !== 'opened' || data.createdFolio.visibility !== 'everyone') {
    throw new Error('user create folio failed')
  }
  if ( data.updatedUser.folios.indexOf(data.createdFolio._id) == -1) {
    throw new Error('user create folio failed -- user did not have folio added')
  }
  return data.createdFolio
}

const addMacheToFolio = async(mache, folio) => {
  const { data } = await axios.post('/f/addMache', {
    folioLocator : { id : folio._id },
    macheLocator : { id : mache._id }
  })
  const updatedFolio = data;

  if ( !updatedFolio.macheSubmissions.map( sub => sub.mache).find( m => m == mache._id ) ) {
    throw new Error('Addmache to folio failed. cant find added mache')
  }

  return data
}

const addDupeToFolio = async(mache, folio) => {
  console.log('%c Expecting a 412 (Maches memberOfFolio...)', 'color:orange')
  const { data } = await axios.post('/f/addMache', {
    folioLocator : { id : folio._id },
    macheLocator : { id : mache._id }
  })
  .then( _ => {
    throw new Error('User was able to add a duplicate mache to a folio')
  })
  .catch(err => {
    if ( !err.response.statusText.includes('already contains') ) {
      throw new Error('Perhaps okay, but error message from user adding duplicate mache to folio is incorrect')
    }
    return true
  })
}


const removeMacheFromFolio = async(mache, folio) => {
  const { data } = await axios.post('/f/removeMache', {
    folioLocator : { id : folio._id },
    macheLocator : { id : mache._id }
  })
  const updatedFolio = data;
  if ( updatedFolio.macheSubmissions.map( sub => sub.mache).find( m => m == mache._id ) ) {
    throw new Error('RemoveMache from folio failed ')
  }

  return data
}



const folioUserTests = async() => {
  try {
    const currentUser = await axios.get('/t/getCurrentUser').then( ({data}) => data )
    const folio = await createFolio();

    const createdMache = await createMache();
    let updatedFolio = await addMacheToFolio(createdMache, folio);
    await addDupeToFolio(createdMache, folio)

    await removeMacheFromFolio(createdMache, folio);



    console.log('%c user folioUserTests ran successfully', "color:blue")
  } catch (err) {
    console.error('Error in user folio tests ', err)
    return err; //for top level test catch
  }
}

// folioUserTests()

export { folioUserTests as default }
