import basicTests from './basics';
import folioTests from './folio';





const run = async() => {
  try {
    console.log('%c Running user tests', 'color:green')
    await basicTests();
    await folioTests();
  } catch (err) {
    console.error('%c User tests failed ', 'color:red', err)
  }
}




export { run as default }
