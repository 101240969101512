





const runBasics = async() => {
  try {
    console.log('%c Running socket mache tests', "color:blue")

    const socket = window.macheSocket
    const message = `testing ${Math.random()}`


    socket.emit('roomBroadcast', { message : message })
    socket.emit('getClientRooms')
    socket.emit('getSocketsInRoom')
    socket.emit('machePlease')
    socket.emit('welcomeToTheMache')



    socket.on('joinedRoom', (data) => {
      console.log('joinedRoom : ', data)
    })

    socket.on('message', (data) => {
      console.log('message : ', data)
    })

    socket.on('broadcast', (data) => {
      console.log('broadcast : ', data)
      if ( message != data.message ) {
        throw new Error('broadcast failed' + data.message)
      }
    })

    socket.on('test', (data) => {
      console.log('test', data)
    })

    socket.on('gotClientRooms', (data) => {
      console.log('gotClientRooms', data)
    })

    socket.on('gotSocketsInRoom', (data) => {
      console.log('gotSocketsInRoom', data)
    })

    socket.on('welcomeToTheMache', (data) => {
      console.log('welcomeToTheMache. should be a mini mache', data)
    })

    socket.on('macheFull', (data) => {
      console.log('machePlease. should be a deep mache', data)
    })

  } catch (err) {
      console.error('Error in socket test basics ', err)
  }
}



export { runBasics as default }
