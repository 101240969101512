import basicTests from './basics';





const run = async() => {
  try {
    console.log('%c Running mache tests', 'color:green')
    await basicTests();
    console.log('%c All mache tests passed', 'color:blue')

  } catch (err) {
    console.error('%c mache tests failed ', 'color:red', err)
  }
}




export { run as default }
