import $ from 'jquery';
import axios from 'axios'



const createGroup = async() => {
  const { data } = await axios.post('/u/createGroup', {
    name : 'Such and much group',
    description : 'A brilliant but meaningless description'
  })
  // console.log('createdGroup ', data)
  return data.createdGroup
}

const leaveGroup = async(group) => {
  const { data } = await axios.post('/u/leaveGroup', { groupLocator : { id : group._id } })
}


const joinGroup = async(group) => {
  const { data } = await axios.post('/u/joinGroup', { groupLocator : { id : group._id } })
}

const pushRandosToGroup = async(group) => {
  const { data } = await axios.post('/t/pushRandomUsersToGroup', { groupId : group._id } )
  return data
}


const deleteGroup = async(group) => {
  const { data } = await axios.post('/u/deleteGroup', { groupLocator : { id : group._id } })
}

const promote = async(group, userToPromote) => {
  const { data } = await axios
  .post('/g/promoteToAdmin', { groupLocator : { id : group._id }, userLocator : { id : userToPromote._id } })

  //4/22 no longer returns updated data -- check should be in server unitTests anyway
  // if ( data.roles.admins.indexOf(userToPromote._id) === -1 ){
  //   throw new Error('user was not correctly promoted to admin')
  // }
  return data;
}

const demote = async(group, userToDemote) => {
  const { data } = await axios
  .post('/g/demoteFromAdmin', { groupLocator : { id : group._id }, userLocator : { id : userToDemote._id } })
  //4/22 no longer returns updated data -- check should be in server unitTests anyway
  // if ( !data.roles.admins.indexOf(userToDemote._id) === -1 ){
  //   throw new Error('user was not correctly demoted from admin')
  // }
}

const kickMember = async(group, userToKick) => {
  const { data } = await axios
  .post('/g/kickMember', { groupLocator : { id : group._id }, userLocator : { id : userToKick._id } })

  //4/22 no longer returns updated data -- check should be in server unitTests anyway
  // if ( !data.members.indexOf(userToKick._id) === -1 ){
  //   throw new Error('user was not correctly kicked from group')
  // }
}

const editGroup = async(group) => {
  const { data } = await axios
  .post('/g/editBasic', { groupLocator : { id : group._id }, name : 'well well well' })
  if ( data.name !== 'well well well' ) {
    throw new Error('Group name was not changed successfully')
  }
}



const runBasics = async() => {
  try {
    const group = await createGroup()
    const { updatedGroup, createdMembers } = await pushRandosToGroup(group)

    await promote(updatedGroup, createdMembers[0] )
    await demote(updatedGroup, createdMembers[0])
    await kickMember(updatedGroup, createdMembers[0])

    await editGroup(group)
    await leaveGroup(group)
    await joinGroup(group)


    const groupToDelete = await createGroup()
    await pushRandosToGroup(groupToDelete)
    await deleteGroup(groupToDelete)
    console.log('%c group basics ran successfully', "color:blue")
  } catch (err) {
      console.error('Error in group basics ', err)
      return err; //for top level test catch
  }


}


export { runBasics as default }
