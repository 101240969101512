import $ from 'jquery';
import axios from 'axios'

//this is pulled from user tests, should probably be in test helpers, or in a test route such that it doesn't log in the user with passport
const createDummyUser = async() => {

  const setUser = async(userId) => {
    // console.log('Calling set user with' , user)
    const { data } = await axios.post('/t/setUser', { userId : userId })
    // console.log('%c Set req.user to : ', 'color:orange', data)
    return data;
  }
  const currentUser = await axios.get('/t/getCurrentUser').then( ({data}) => data )
  const randNumber = Math.floor( Math.random()*100000 );
  const code = await axios.post('/t/createCode', { code : `beepBoop_${randNumber}`, singleUse : false }).then( res => res.data)
  console.log('logging the code', code.code)
  const { data } = await axios.post('/u/register', {
    email : `avsp.here_${randNumber}@gmail.com`,
    username : `avsphere_${randNumber}`,
    consented : true,
    password : 'SomePassword_1',
    code : code.code
  })
  await setUser(currentUser._id)
  return data.newUserId
}



const createMache = async(visibility='public') => {
  const randNumber = Math.floor( Math.random()*100000 );
  const { data } = await axios.post('/u/createMache', {
    title : `The best mache ${randNumber}`,
    visibility : visibility
  })
  const { createdMache } = data;
  console.log('created mache', createdMache)
  if ( createdMache.title !== 'The mache' && createdMache.settings.visibility !== visibility ) {
    throw new Error('user create mache failed')
  }

  return createdMache
}

const getDeepMaches = async(createdMaches) => {
  const maches = await axios.post('/e/getMaches', {
    macheLocator : { keys : createdMaches.map( m => m.key) },
    depth : 'deep'
  }).then( res => res.data.map( m => m ) )
  console.log('%c Deep maches', 'color:green', maches)
  return maches
}

const getMiniMaches = async(createdMaches) => {
  const maches = await axios.post('/e/getMaches', {
    macheLocator : { keys : createdMaches.map( m => m.key) },
    depth : 'mini'
  }).then( res => res.data.map( m => m ) )
  console.log('%c Mini maches', 'color:green', maches)
  return maches
}

const getMaches = async(createdMaches) => {
  const maches = await axios.post('/e/getMaches', {
    macheLocator : { keys : createdMaches.map( m => m.key) }
  }).then( res => res.data.map( m => m ) )
  console.log('%c Normal maches', 'color:green', maches)
  return maches
}


//FIX ME -- this is not a very comprehensive test
const copyMache = async(macheToCopy) => {
  const copiedMache = await axios.post('/e/copyMache', {
    macheLocator : { id : macheToCopy._id }
  }).then( res => res.data )
  .catch( err => {
    console.log(err, err.message)
  })
  console.log('%c Copied Mache', 'color:green', copiedMache)
  return copiedMache
}

const setUserAccess = async() => {

  const randNumber = Math.floor( Math.random()*1000000 );
  const { data } = await axios.post('/u/createMache', {
    title : `The best mache ${randNumber}`,
    visibility : 'public'
  })
  const { createdMache } = data;

  const dummyUserId = await createDummyUser();

  await axios.post('/e/setUserAccess', {
    macheLocator : { id : createdMache._id },
    userLocator : { id : dummyUserId },
    roleLocator : { name : 'editor', context : 'mache' }
  })

  let [ updatedMache ] = await getDeepMaches([createdMache])

  console.log('updated mache ', updatedMache)
  if ( updatedMache.accessors.length !== 2) { throw new Error('setUserAccess invalid accessor count') }

  await axios.post('/e/setUserAccess', {
    macheLocator : { id : createdMache._id },
    userLocator : { id : dummyUserId },
    roleLocator : { name : 'editor', context : 'mache' },
    delete : true
  })

  updatedMache = await getDeepMaches([createdMache]).then( d => d.pop() )
  if ( updatedMache.accessors.length !== 1) { throw new Error('setUserAccess invalid accessor count')}



}




const runBasics = async() => {
  try {
    const createdMaches = [ await createMache('private'), await createMache() ];

    const deepMaches = await getDeepMaches(createdMaches)
    const miniMaches = await getMiniMaches(createdMaches)
    const normalMaches = await getMaches(createdMaches)

    const copiedMache = await copyMache(createdMaches[0])
    await setUserAccess();

    console.log('%c macheBasics ran successfully', "color:blue")
  } catch (err) {
      console.error('Error in mache basics ', err)
  }
}


export { runBasics as default }
