
const testSockets = {}

const exampleEvents = (socket) => {
  console.log('%c Setting test socket in window as testSocket', 'color:green')
  socket.on('example_pong', (data) => {
    console.log('%c caught example_pong event with data : ', 'color:blue', data)

    const response = 'hi friends'
    console.log('%c example_pong handler emitting example_response', 'color:blue', response)

    socket.emit('example_response', { data : response } )
  })



}





const randomNumber = Math.random()

testSockets.init = (roomKey) => {
  // const socket = io('/testSpace'); //exposed on window object from layout.pug
  const socket = io('/testSpace', { 'transports': ['websocket'], query: "foo=bar" }); //exposed on window object from layout.pug

  window.testSocket = socket;



  // console.log(`%c Joining room ${roomKey}`, 'color:green')
  // socket.emit('joinRoom', { room : roomKey })
  console.log(`%c Joining mache ${roomKey}`, 'color:green')
  socket.emit('joinMache', { macheKey : roomKey })
  socket.emit('getRooms')
  socket.emit('getClientRooms')
  socket.emit('roomBroadcast', { message : 'hi! this is to everyone including me' })
  socket.emit('clientExcludedBroadcast', { message : 'hey lol, this is to everyone EXCEPT me'})

  socket.on('joinedRoom', (data) => {
    console.log('joinedRoom : ', data)
  })

  socket.on('getRooms', (data) => {
    console.log('%c All current rooms : ', 'color:green', data)
  })

  socket.on('getClientRooms', (data) => {
    console.log('%c Clients rooms : ', 'color:green', data)
  })

  socket.on('gotActiveMache', (data) => {
    console.log('%c Recieved active mache : ', 'color:green', data)
  })


  socket.on('broadcast', (data) => {
    console.log('%c Recieved a broadcast : ', 'color:green', data)
  })

  setTimeout( () => {
    socket.emit('messageRoom', { message : 'hi lol : ' + randomNumber })
  }, 5000)




}




export { testSockets as default }
