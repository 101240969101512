import groupTests from './group/allTests.js';
import userTests from './user/allTests.js';
import macheTests from './mache/allTests.js';
import socketTests from './sockets/basic.js';
import axios from 'axios'

const logic = {}

logic.groupTests = groupTests
logic.userTests = userTests
logic.macheTests = macheTests
logic.getCurrentUser = () => axios.get('/t/getCurrentUser').then( ({data}) => data )

logic.socketTests = socketTests;

logic.runAll = async() => {
  try {
    const currUser = await logic.getCurrentUser()
    if ( !currUser ) { throw new Error('You must log in before running all the tests') }
    await axios.get('/t/consentMe')
    await axios.get('/t/codeMe')
    await groupTests();
    await macheTests();
    await userTests();
  } catch (err) {
    console.error('runAll tests failed : ', err)
  }
}



export { logic as default }
