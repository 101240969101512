import basicTests from './basics';





const run = async() => {
  try {
    console.log('%c Running group tests', 'color:green')
    await basicTests();
    console.log('%c All group tests passed', 'color:blue')

  } catch (err) {
    console.error('%c Group tests failed ', 'color:red', err)
  }
}




export { run as default }
