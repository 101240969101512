import axios from 'axios'



const register = async() => {
  const randNumber = Math.floor( Math.random()*100 );
  const code = await axios.post('/t/createCode', { code : `beepBoop_${randNumber}`, singleUse : false }).then( res => res.data)
  console.log('logging the code', code.code)
  const { data } = await axios.post('/u/register', {
    email : `avsp.here_${randNumber}@gmail.com`,
    username : `avsphere_${randNumber}`,
    consented : true,
    password : 'SomePassword_1',
    code : code.code
  })

  return data.newUserId
}

const editBasic = async() => {
  const { data } = await axios.post('/u/editBasic', {
    bio : 'very fancy',
    name : 'why yes'
  })
  // console.log('after edit basic', data)
  return data
}

const resetPassword = async() => {
  const { data } = await axios.post('/u/resetPassword', {
    oldPassword : 'SomePassword_1',
    newPassword : 'SomePassword_2'
  })
  return data
}

const setUser = async(userId) => {
  console.log('Calling set user with' , user)
  const { data } = await axios.post('/t/setUser', { userId : userId })
  console.log('%c Set req.user to : ', 'color:orange', data)
  return data;
}


const runBasics = async() => {
  try {
    const currentUser = await axios.get('/t/getCurrentUser').then( ({data}) => data )
    console.log('currentUser is ', currentUser)
    const newUser = await register()
    await setUser(newUser)

    await editBasic()
    await resetPassword()

    await setUser(currentUser)


    console.log('%c user basics ran successfully', "color:blue")
  } catch (err) {

      console.error('Error in user lifecylce ', err)
  }
}


export { runBasics as default }
