import $ from 'jquery';
import 'popper.js';
import 'bootstrap'
import axios from 'axios'
import qs from 'qs'
import loginAndRegister from './loginAndRegister.js';
import auth from './auth.js';
import tests from '../test/test.js';
import sockets from '../socketIoClient/sockets.js'

if ( NODE_ENV === 'dev' ) {
  window.axios = axios
  window.$ = $
  window.consentMe = () => axios.get('/t/consentMe')
  window.codeMe = () => axios.get('/t/codeMe')
  window.getCurrentUser = () => axios.get('/t/getCurrentUser')
  window.tests = tests
  window.qs = qs;
  window.onerror = function(error, url, line ) {
    console.log("Beep boop beep error ", error, url, line)
  }
}



loginAndRegister.init()
  .catch(e => console.error('Error in loginAndRegister.init', e))


/** Headband button handling **/
$('#userTopRight').on('click', async(el) => {
  console.log(el.target);
  el.preventDefault();
  el.stopPropagation();
  if($("#userOptions").hasClass('closed-state'))
    $("#userOptions").removeClass("closed-state").addClass("open-state");
  else
    $("#userOptions").removeClass("open-state").addClass("closed-state");
})

$('body').on('click', async(el) => {
  $("#userOptions").removeClass("open-state").addClass("closed-state");

  if(el.target.id == 'loginModal')
    $("#loginModal").removeClass().addClass("closed-state");
})


$('#closeLoginModal').on('click', async(el) => {
  $("#loginModal").removeClass().addClass("closed-state");
})


/** MacheListView button handling **/
